import {tableConfig} from "../../../entity";
import React from "react";
import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Space, Tooltip} from "antd";
import {getBaseTableColumns} from "../../../utils";

export function getTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => []):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '反馈类型',
            dataIndex: 'rebackType',
            search: false,
        },
        {
            title: 'bug页面',
            dataIndex: 'bugPage',
            search: false,
        },
        {
            title: '手机品牌',
            dataIndex: 'brandName',
            search: false,
        },
        {
            title: 'SDK版本',
            dataIndex: 'sdkVersion',
            search: false,
        },
        {
            title: '安卓版本',
            dataIndex: 'androidVersion',
            search: false,
        },
        {
            title: 'bug描述',
            dataIndex: 'description',
            search: false,
            render(_, record) {
                return (
                   <Space>
                       <Tooltip title={record.description}>
                           <div style={{maxWidth: "80px",whiteSpace:"nowrap",textOverflow:"ellipsis"}}>{record.description}</div>
                       </Tooltip>
                   </Space>
                )
            }
        },
        ...getBaseTableColumns(fns,reactElementFn,false,true,false)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        valueType:"divider"
    },
    {
        title:"基础信息",
        valueType:"group",
        columns:[
            {
                title: '用户名',
                dataIndex: 'name',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入用户名',
                        },
                    ],
                }
            },
            {
                title: '电话',
                dataIndex: 'phone',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入电话',
                        },
                    ],
                }
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入邮箱',
                        },
                    ],
                }
            }
        ]
    },
]
export const startData = {
    status:true
}