import {PromotionPlatform, PromotionType, tableConfig} from "../../../entity";
import React, {useState} from "react";
import {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {PlusOutlined} from '@ant-design/icons';
import {Image, Tag, Upload, UploadFile, UploadProps} from "antd";

import {getBaseTableColumns} from "../../../utils";
import CosUtil from "../../../utils/cos";
import type {FormInstance} from "antd/lib/form";
import {GetProp} from "../../../entity/types";


export function getTableColumns(fns: tableConfig, reactElementFn: (record: any) => React.ReactElement[] = (_) => []): ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '标题',
            dataIndex: 'name',

        },
        {
            title: '推荐理由',
            dataIndex: 'reason',

        },
        {
            title: '类型',
            dataIndex: 'type',
            render: (_, record: any) => {
                switch (record.type as PromotionType) {
                    case "BANNER":
                        return <Tag color="geekblue">频道</Tag>
                    case "SINGLE":
                        return  <Tag color="geekblue">单品</Tag>
                }
            },
            valueEnum: TypeEnum
        },
        {
            title: '平台',
            dataIndex: 'platform',

            valueEnum: PlatFormEnum,
            render: (_, record: any) => {
                switch (record.platform as PromotionPlatform) {
                    case "E_LE_MANG":
                        return <Tag color="geekblue">饿了么</Tag>
                    case "JING_DONG":
                        return  <Tag color="geekblue">京东</Tag>
                    case "MEI_TUAN":
                        return <Tag color="geekblue">美团</Tag>
                    case "PIN_DUO_DUO":
                        return  <Tag color="geekblue">拼多多</Tag>
                    case "TAO_BAO":
                        return  <Tag color="geekblue">淘宝</Tag>
                }
            }
        },
        {
            title: '价格',
            dataIndex: 'price',
            search: false,
        },
        {
            title: '券后价',
            dataIndex: 'afterTicketPrice',
            search: false,
        },
        {
            title: '图片',
            dataIndex: 'image',

            search: false,
            render: (_, record: any) => {
                let images = record['image'].split(",")
                return <>
                    {
                        images.map((item:string,_:number) => {
                            return <Image style={{width:"50px",height:"50px"}} src={item}></Image>
                        })
                    }
                </>
            }
        },
        {
            title: '普通推广链接',
            dataIndex: 'mobileUrl',
            search: false,
            hideInTable:true
        },
        {
            title: 'APP跳转链接',
            dataIndex: 'schemaUrl',
            search: false,
            hideInTable:true
        },
        ...getBaseTableColumns(fns, reactElementFn, false, true, false)
    ];
}

const PlatFormEnum = {
    PIN_DUO_DUO: {
        text: '拼多多',
        status: 'PIN_DUO_DUO',
    },
    TAO_BAO: {
        text: '淘宝',
        status: 'TAO_BAO',
    },
    JING_DONG: {
        text: '京东',
        status: 'JING_DONG',
    },
    MEI_TUAN: {
        text: '美团',
        status: 'MEI_TUAN',
    },
    E_LE_MANG: {
        text: '饿了么',
        status: 'E_LE_MANG',
    },
};
const TypeEnum = {
    SINGLE: {
        text: '单品',
        status: 'SINGLE',
    },
    BANNER: {
        text: '活动',
            status: 'BANNER',
    },
};
const LocationEnum = {
    HOME: {
        text: '主页',
        status: 'HOME',
    },
    ANIMA_DETAIL: {
        text: '动漫详情页',
        status: 'ANIMA_DETAIL',
    },
    ANIMA_PLAY: {
        text: '动漫播放页',
        status: 'ANIMA_PLAY',
    },
    SHOP_ENTRY: {
        text: '商城入口',
        status: 'SHOP_ENTRY',
    },
};
export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex: "id",
        colProps: {
            span: 0
        },
        renderFormItem: () => {
            return <div style={{display: "none"}}></div>
        }
    },
    {
        valueType: "divider"
    },
    {
        title: "基础信息",
        valueType: "group",
        columns: [
            {
                title: '标题',
                dataIndex: 'name',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '标题',
                        },
                    ],
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                colProps: {
                    span: 6
                },
                valueEnum: TypeEnum
            },
            {
                title: '平台',
                dataIndex: 'platform',
                colProps: {
                    span: 6
                },
                valueEnum: PlatFormEnum
            }, {
                title: '价格',
                dataIndex: 'price',
                colProps: {
                    span: 6
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '券后价',
                dataIndex: 'afterTicketPrice',
                colProps: {
                    span: 6
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '券后价',
                        },
                    ],
                }
            },
            {
                title: '所在位置',
                dataIndex: 'location',
                colProps: {
                    span: 24
                },
                valueEnum: LocationEnum
            },
            {
                title: '图片链接',
                dataIndex: 'image',
                colProps: {
                    span: 24
                },
                renderFormItem:(schema, config, form)=>{
                    return <UploadImage form={form}></UploadImage>
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入图片链接',
                        },
                    ],
                }
            },

            {
                title: '普通长链',
                dataIndex: 'mobileUrl',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入普通长链',
                        },
                    ],
                }
            },
            {
                title: 'APP跳转链接',
                dataIndex: 'schemaUrl',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },

            {
                title: '推荐理由',
                dataIndex: 'reason',
                colProps: {
                    span:24
                },
                valueType:"textarea",
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '标题',
                        },
                    ],
                }
            },


        ]
    },
]
export const startData = {
    status: true,
    image:""
}


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const UploadImage: React.FC<{ form: FormInstance }> = (props) => {
    const {form} = props
    const image = form.getFieldValue("image") as String
    let  images:UploadFile[] = []
    if(image){
        images = image.split(",").map((item,index)=>{
            return {
                uid: `-${index}`,
                name: 'image.png',
                status: 'done',
                url: item,
            }
        })
    }

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>(images);
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = (data)=>{
        console.log(data)
        let image = data.fileList.map(item=>item.url).join(",")
        form.setFieldValue("image",image)
        setFileList(data.fileList);
    }


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    const customRequest:UploadProps['customRequest'] = (options)=> {
        console.log(options)

        CosUtil.uploadFile({
            Body: options.file,
            Headers: undefined,
            // @ts-ignore
            Key: `image/${options.file.name}`,
            onProgress(data) {
                options!.onProgress!({percent:data.percent})
            },
            onTaskReady: undefined
        }).then((data) => {
            //`https://${data.Location}`
            images.push({
                uid: `-${images.length}`,
                name: 'image.png',
                status: 'done',
                url:`https://${data.Location}`,
            });

            let image = images.map(item=>item.url).join(",")
            form.setFieldValue("image",image)
            setFileList(images)
        }).catch(options.onError)
    }
    return (
        <>
            <Upload

                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                customRequest={customRequest}
            >
                {fileList.length >= 8 ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
};
