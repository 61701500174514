import {tableConfig} from "../../../entity";
import React, {useState} from "react";
import {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {InboxOutlined} from '@ant-design/icons';
import {message, Progress, Space, Tag, Tooltip, Upload, UploadProps} from "antd";

import {getBaseTableColumns} from "../../../utils";
import CosUtil from "../../../utils/cos";
import type {FormInstance} from "antd/lib/form";
import {RcFile} from "antd/es/upload";

const {Dragger} = Upload;

export function getTableColumns(fns: tableConfig, reactElementFn: (record: any) => React.ReactElement[] = (record) => []): ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '版本',
            dataIndex: 'version',
            search: false,
        },
        {
            title: '下载链接',
            dataIndex: 'downloadUrl',
            search: false,
        },
        {
            title: '云盘链接',
            dataIndex: 'yunpanUrl',
            search: false,
        },
        {
            title: '云盘密码',
            dataIndex: 'yunpanPassword',
            search: false,
        },
        {
            title: 'APP分享页面',
            dataIndex: 'webShareUrl',
            search: false,
        },
        {
            title: '更新日志',
            dataIndex: 'updateLog',
            search: false,
            render(_, record) {
                return (
                    <Space>
                        <Tooltip title={record.updateLog}>
                            <div style={{
                                maxWidth: "80px",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis"
                            }}>{record.updateLog}</div>
                        </Tooltip>
                    </Space>
                )
            }
        },
        {
            title: '是否是最新版本',
            dataIndex: 'active',
            search: false,
            render: (_, record: any) => {
                if (record.active) {
                    return (
                        <Tag color="geekblue">是</Tag>
                    )
                } else {
                    return (
                        <Tag>否</Tag>
                    )
                }
            }
        },
        ...getBaseTableColumns(fns, reactElementFn, false, true, false)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex: "id",
        colProps: {
            span: 0
        },
        renderFormItem: () => {
            return <div style={{display: "none"}}></div>
        }
    },
    {
        valueType: "divider"
    },
    {
        title: "基础信息",
        valueType: "group",
        columns: [
            {
                title: '版本',
                dataIndex: 'version',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入用户名',
                        },
                    ],
                }
            },
            {
                title: '下载链接',
                dataIndex: 'downloadUrl',
                colProps: {
                    span: 24
                },
                renderFormItem: (schema, config, form) => {
                    return <UploadOSS form={form}></UploadOSS>

                }
                // formItemProps: {
                //     rules: [
                //         {
                //             required: true,
                //             message: '请输入电话',
                //         },
                //     ],
                // }
            },
            {
                title: '云盘链接',
                dataIndex: 'yunpanUrl',
                colProps: {
                    span: 12
                },
            },
            {
                title: '云盘密码',
                dataIndex: 'yunpanPassword',
                colProps: {
                    span: 12
                },
            },
            {
                title: 'APP分享页面',
                dataIndex: 'webShareUrl',
                colProps: {
                    span: 24
                },
                formItemProps: {

                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '更新日志',
                dataIndex: 'updateLog',
                valueType: "textarea",
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },

        ]
    },
]
export const startData = {
    status: true,
    webShareUrl:"http://154.197.26.216:8080/download/theme1/index.html"
}

function UploadOSS(prop: { form: FormInstance }) {
    const {form} = prop
    const [progress, setProgress] = useState(0)
    const [showProgress, setShowProgress] = useState(false)
    const uploadProps: UploadProps = {
        multiple: true,
        defaultFileList: [],
        fileList: [],
        beforeUpload(file, fileList) {
            if(!form.getFieldValue("version")){
                message.warning('请填写版本');
                return false
            }
            const isLt500M = file.size / 1024 / 1024 > 500;
            if (isLt500M) {
                message.warning('超过最大文件上传大小500Mb');
                return false
            }
            return true; // 不调用默认的上传方法
        },
        onChange(file){
            if(file.file.status==='uploading'){
                setShowProgress(true);
            }
        },
        progress: {
            showInfo: true,
        },
        customRequest(options) {
            CosUtil.uploadWorker(options.file as RcFile,`shenqihezi-${form.getFieldValue("version")}.apk`,(percent)=>{
                setProgress(percent)
            }).then((data:any)=>{
                if(typeof data === 'string' ){
                    data = JSON.parse(data)
                }
                form.setFieldValue("downloadUrl", data.data)
                console.log(data)
            }).catch(err=>{
                console.log(err)
            })
            // CosUtil.uploadFile({
            //     Body: options.file,
            //     Headers: undefined,
            //     Key: `app/shenqihezi-${form.getFieldValue("version")}.apk`,
            //     onProgress(data) {
            //         setProgress(data.percent*100)
            //     },
            //     onTaskReady: undefined
            // }).then((data) => {
            //     form.setFieldValue("downloadUrl", `https://${data.Location}`)
            // }).catch(options.onError)
        },
    };

    return (
        // <Upload {...uploadProps}>
        //     <Button icon={<UploadOutlined />}>Upload</Button>
        // </Upload>
        <Dragger {...uploadProps}>
            {showProgress ?
                <>
                    <Progress type="circle" percent={progress} />
                    <p className="ant-upload-hint">
                        {form.getFieldValue("downloadUrl")}
                    </p>
                </>
              : <>
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">点击或者拖入你要上传的版本</p>
                    <p className="ant-upload-hint">
                        上传你的app
                    </p>
                </>

            }


        </Dragger>
    )
}
