import {extend} from 'umi-request';
import {SESSION_TOKEN_ITEM} from "../utils/constants";


// const DOMAIN_URL = "http://154.197.26.216:8087"
// const DOMAIN_URL =  "http://127.0.0.1:8087"
const DOMAIN_URL = "https://sqhzapp.org/api"
export const api = extend({
    prefix: DOMAIN_URL,
    timeout: 3000,
});
// 在请求拦截器设置token
api.interceptors.request.use((url, options)=>{
    // 统一设置token
    const token = sessionStorage.getItem(SESSION_TOKEN_ITEM)
    if(token){
        options = {
        ...options,
                headers:{
                token:token
            }
        }
    }
    return {
        url,
        options
    }
})

// 在响应拦截器统一返回token
api.interceptors.response.use( async (response, options)=>{
    // 统一设置token
    // const data = await response.clone().json();
    return response
})



















