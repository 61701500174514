import React, {useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {RoleDto, RoleVo} from "../../../entity/System/Role";
import {formColumns, getTableColumns, startData} from "../Role/RoleConfig";
import {addRole, delRole, getRoleList} from "../../../apis/System/RoleApi";
import {PlusOutlined} from "@ant-design/icons";
import {MenuVo} from "../../../entity/System/Menu";
import {DeptVo} from "../../../entity/System/Dept";

type lableValue = {
    label:string,
    value:number
}
function getIds(menus:MenuVo[]|DeptVo[]):number[]{
    let ids:number[] = []

    menus.forEach((menu)=>{
        ids.push(menu.id as number)
        if(menu.children !==undefined){
            ids = ids.concat(getIds(menu.children||[]))
        }
    })
    return ids
}
// function getAllNodeIdsIncludingChildren(childIds: number[], menuData: MenuVo[]): number[] {
//     const nodeIds = new Set<number>();
//     function findNodeById(id: number, data: MenuVo[]): MenuVo | undefined {
//         for (const item of data) {
//             if (item.id === id) {
//                 return item;
//             }
//             if (item.children) {
//                 const found = findNodeById(id, item.children);
//                 if (found) {
//                     nodeIds.add(item.id as number); // 添加父节点 ID
//                     return found;
//                 }
//             }
//         }
//         return undefined;
//     }
//     function traverse(node: MenuVo, data: MenuVo[]): void {
//         if (node) {
//             nodeIds.add(node.id as number); // 添加当前节点的 ID
//             if (node.children) {
//                 node.children.forEach((child) => traverse(child, data)); // 递归遍历子节点
//             }
//         }
//     }
//     // 首先，找到所有子节点
//     childIds.forEach((childId) => {
//         const node = findNodeById(childId, menuData);
//         if (node) {
//             traverse(node, menuData); // 从找到的子节点开始遍历
//         }
//     });
//     return Array.from(nodeIds);
// }
export default function Role (){
    const [modelShow, setModelShow] = useState(false)
    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    return (
        <div>
            <BaseTable<RoleVo,RoleDto>
                tableConfig = {{
                    columns:getTableColumns({
                        editFn:(record)=>{
                            form.setFieldsValue(record)
                            const menuIds = getIds(record.menus)
                            const deptIds = getIds(record.depts)
                            form.setFieldValue("menuIds",menuIds)
                            form.setFieldValue("deptIds",deptIds)
                            if(record.status==="SHOW"){
                                form.setFieldValue("status",true)
                            }else{
                                form.setFieldValue("status",false)
                            }
                            setModelShow(true)
                        },
                        deleteFn:async (record)=>{

                            await delRole(record.id)
                            actionRef.current?.reload()
                            notification.success({
                                message:"删除成功"
                            })
                        },
                    }),
                    actionRef:actionRef,
                    cardBordered:true,
                    rowKey:"id",
                    toolBarRender:()=> [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setModelShow(true)
                            }}
                            type="primary"
                        >
                            新建
                        </Button>
                    ],
                    request:async (params, sort, filter) => {
                        console.log(sort, filter,params)
                        const result =  await getRoleList({
                            page:params.current,
                            size:params.pageSize,
                            roleName:params.roleName
                        });
                        const page = result.data
                        return {
                            data: page?.list ,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: result.success,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: page?.total,
                        }
                    },
                    pagination:{
                        pageSize: 10,
                    },
                    search:false,
                    headerTitle:"用户数据"
                }}
                formConfig={{
                    form:form,
                    initialValues:startData,
                    shouldUpdate:true,
                    title:"新建部门",
                    grid:true,
                    layoutType:"ModalForm",
                    open:modelShow,
                    onOpenChange:setModelShow,
                    columns:formColumns,
                    onFinish: async (formData)=>{
                        formData.status = formData.status?"SHOW":"HIDDEN"
                        const menuLabels = form.getFieldValue("menuIds") as any as (lableValue[]|number[])
                        const menuIds = menuLabels.map(item=>{
                            console.log(item)
                            if(typeof item == 'number'){
                                return item
                            }else{
                                return item.value
                            }
                        })
                        formData.menuIds =menuIds
                        if(formData.dataScope === "SELF_CUSTOM"){
                            const deptLabels = form.getFieldValue("deptIds") as any as (lableValue[]|number[])
                            const deptIds = deptLabels.map(item=>{
                                console.log(item)
                                if(typeof item == 'number'){
                                    return item
                                }else{
                                    return item.value
                                }
                            })
                            formData.deptIds =deptIds
                        }
                        await addRole(formData)
                        actionRef.current?.reload()
                        setModelShow(false)
                        return true
                    },
                    modalProps:{
                        destroyOnClose:true,
                        mask:true
                    }
                }}
            ></BaseTable>
        </div>
    )
}