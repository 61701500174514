import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxVideoAuditDto} from "../../entity/Godbox/VideoAudit";


async function getRebackPage(menuDto: GodboxVideoAuditDto):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/reback/page',{
        data:menuDto
    });
    requestTest(res)
    return res
}


async function delReback(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/reback/delete',{
        data:{
            id
        }
    });
    return requestTest(res)
}

export {
    getRebackPage,
    delReback
}