import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Image, Space} from "antd";
import React from "react";
import {getDeptList} from "../../../apis/System/DeptApi";
import {buildDeptSelect, buildRoleSelect, getBaseTableColumns} from "../../../utils";
import {getRoleList} from "../../../apis/System/RoleApi";
import {tableConfig} from "../../../entity";


export const waitTimePromise = async (time: number = 100) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};

export const waitTime = async (time: number = 100) => {
    await waitTimePromise(time);
};

const valueEnum = {
    MALE: {
        text: '男',
        status: 'MALE'
    },
    FEMALE: {
        text: '女',
        status: 'FEMALE',
    },
    UNKNOWN: {
        text: '未知',
        status: 'UNKNOWN',
    },
};
export function getTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => []):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '用户名',
            dataIndex: 'name',
        },
        {
            title: '头像',
            dataIndex: 'avatar',
            search: false,
            render(_, record) {
                return (
                    <Space>
                        <Image
                            width={50}
                            src={record.avatar}
                        />
                    </Space>
                )
            }
        },
        {
            title: '昵称',
            dataIndex: 'nickName',
            search: false,
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            search: false,
        },
        {
            title: '电话',
            dataIndex: 'phone',
        },
        {
            title: '部门',
            dataIndex: ['dept','name'],
        },
        {
            title: '角色',
            dataIndex: ['role','roleName'],
        },
        ...getBaseTableColumns(fns,reactElementFn)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        valueType:"divider"
    },
    {
        title:"基础信息",
        valueType:"group",
        columns:[
            {
                title: '用户名',
                dataIndex: 'name',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入用户名',
                        },
                    ],
                }
            },
            {
                title: '电话',
                dataIndex: 'phone',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入电话',
                        },
                    ],
                }
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入邮箱',
                        },
                    ],
                }
            }
        ]
    },
    {
        valueType:"divider"
    },
    {
        title:"额外信息",
        valueType:"group",
        columns:[
            {

                title: '性别',
                dataIndex: 'sex',
                colProps: {
                    span: 12
                },
                valueType:"radio",
                valueEnum
            },
            {
                title: '状态',
                dataIndex: 'status',
                colProps: {
                    span: 12
                },
                valueType:"switch",
            },
            {
                title: '角色',
                dataIndex: 'roleId',
                colProps: {
                    span: 24
                },
                valueType:"select",
                request:async (params,props) => {
                    const result = await getRoleList({})
                    return buildRoleSelect(result.data?.list||[])
                }
            },
            {
                title: '部门',
                dataIndex: 'departmentId',
                valueType: 'treeSelect',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [ ],
                },
                request:async (params,props) => {
                    const result = await getDeptList({})
                    return buildDeptSelect(result.data||[])
                }
            },

        ]
    },

]
export const startData = {
    status:true
}