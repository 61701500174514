import React, {useState} from 'react';
import "./IconPicker.scss"
import * as icons from '@ant-design/icons';
import type {TabsProps} from 'antd';
import {Input, Tabs} from "antd";
import {IconKeys} from "../../entity";



const Icon = icons.default

let keys = Object.keys(icons).filter(key=>{
    return (key.endsWith('Outlined')||key.endsWith('Filled')||key.endsWith('TwoTone'))
})

type IconMapKey = 'Outlined'|'Filled'|'TwoTone'
type IconMapType = {
    [key in IconMapKey]:IconKeys[]
}
function getOutlinedItems(onClick:(key:IconKeys)=>void) {
    return <div className='icon-container'>
        {
            getIconMap().Outlined.map(key => {
                return (
                    <div className='icon-item-container' onClick={e=>onClick(key)}>
                        <Icon component={icons[key] as React.ForwardRefExoticComponent<any>} />
                        <div className='key'>{key}</div>
                    </div>
                )
            })
        }
    </div>;
}
function getFilledItems(onClick:(key:IconKeys)=>void) {
    return <div className='icon-container'>
        {
            getIconMap().Filled.map(key => {
                return (
                    <div className='icon-item-container' onClick={e=>onClick(key)}>
                        <Icon component={icons[key] as React.ForwardRefExoticComponent<any>} />
                        <div className='key'>{key}</div>
                    </div>
                )
            })
        }
    </div>;
}
function getTwoToneItems(onClick:(key:IconKeys)=>void) {
    return <div className='icon-container'>
        {
            getIconMap().TwoTone.map(key => {
                return (
                    <div className='icon-item-container' onClick={e=>onClick(key)}>
                        <Icon component={icons[key] as React.ForwardRefExoticComponent<any>} />
                        <div className='key'>{key}</div>
                    </div>
                )
            })
        }
    </div>;
}

function getItems(onClick:(key:IconKeys)=>void):TabsProps['items']{
    const items: TabsProps['items'] = [
        {
            key: 'Outlined',
            label: 'Outlined',
            children: getOutlinedItems(onClick),
        },
        {
            key: 'Filled',
            label: 'Filled',
            children: getFilledItems(onClick),
        },
        {
            key: 'TwoTone',
            label: 'TwoTone',
            children: getTwoToneItems(onClick),
        },
    ];
    return items
}

function getIconMap():IconMapType {
    let IconMap:IconMapType = {
        Outlined:[],
        Filled:[],
        TwoTone:[]
    }
    keys.forEach(key=>{
        if(key.includes('Outlined')){
            IconMap.Outlined.push(key as IconKeys)
        }
        if(key.includes('Filled')){
            IconMap.Filled.push(key as IconKeys)
        }
        if(key.includes('TwoTone')){
            IconMap.TwoTone.push(key as IconKeys)
        }
    })
    return IconMap
}

type IconPickertype = {
    id?:string
    onBlur?:()=>void
    onChange:(key:IconKeys)=>void
    value?:any
}

const IconPicker:React.FC<IconPickertype> = (props, context) => {
    const [showIconPicker, setShowIconPicker] = useState(false)
    const [inputValue, setInputValue] = useState(props.value)
    const [disableInput, setDisableInput] = useState(false)
    const onFocus = ()=>{
        setShowIconPicker(true)
        setDisableInput(true)
    }
    const onBlur= ()=>{
        // setShowIconPicker(false)
    }
    const onChange = (key: string) => {
        console.log(key);
    };
    const onClick= (key:IconKeys)=>{
        props.onChange(key)
        setInputValue(key)
        setShowIconPicker(false)
        setDisableInput(false)
    }
    return (
        <div className="icon-picker">
            <Input onFocus={e=>onFocus()}
                   onBlur={e=>onBlur()}
                   placeholder="请选择你的图标"
                   value={inputValue}
                   disabled={disableInput}
            />
            { showIconPicker &&
                <div className='icon-picker-container'>
                    <Tabs defaultActiveKey="1" items={getItems(onClick)} onChange={onChange} />
                </div>
            }
        </div>
    )
};

export default IconPicker;