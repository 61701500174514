import {createBrowserRouter, Navigate,} from "react-router-dom";
import React from "react";

import Home from "../Home";
import Login from "../views/Login/Login";
import DashBoard from "../views/DashBoard/DashBoard";
import Menu from "../views/System/Menu/Menu";
import User from "../views/System/User/User";
import Role from "../views/System/Role/Role";
import Dept from "../views/System/Dept/Dept";

import GodBoxKeyword from "../views/GodBox/Keyword/Keyword";
import GodBoxUser from "../views/GodBox/User/User";
import GodboxVideoAudit from "../views/GodBox/VideoAudit/VideoAudit";
import GodboxReback from "../views/GodBox/Reback/Reback";
import GobBoxDownload from "../views/GodBox/Download/Download";
import GobBoxPromotion from "../views/GodBox/Promotion/Promotion";
import AuthRoute from "../views/AuthRoute/AuthRoute";
import GobBoxDataSource from "../views/GodBox/DataSource/DataSource";


export default createBrowserRouter([
    {
        path: "/",
        element: <AuthRoute></AuthRoute>,
        children: [
            {
                path: "/system",
                element: <Home/>,
                children: [
                    {
                        path: "dashboard",
                        element:<DashBoard></DashBoard>
                    }
                ]
            }
        ]
    },
    {
        path: "/",
        element: <AuthRoute></AuthRoute>,
        children: [
            {
                path: "/godbox",
                element: <Home/>,
                children: [
                    {
                        path: "",
                        element:<Navigate to="/godbox/user"></Navigate>
                    },
                    {
                        path: "user",
                        element: <GodBoxUser></GodBoxUser>
                    },
                    {
                        path: "keyword",
                        element: <GodBoxKeyword></GodBoxKeyword>
                    },
                    {
                        path: "video-audit",
                        element: <GodboxVideoAudit></GodboxVideoAudit>
                    },
                    {
                        path: "reback",
                        element: <GodboxReback></GodboxReback>
                    },
                    {
                        path: "download",
                        element: <GobBoxDownload></GobBoxDownload>
                    },
                    {
                        path: "promotion",
                        element: <GobBoxPromotion></GobBoxPromotion>
                    },
                    {
                        path: "datasource",
                        element: <GobBoxDataSource></GobBoxDataSource>
                    },
                   ]
            }
        ]
    },
    {
        path: "/",
        element: <AuthRoute></AuthRoute>,
        children: [
            {
                path: "/system",
                element: <Home/>,
                children: [
                    {
                        path: "",
                        element:<Navigate to="/system/user"></Navigate>
                    },
                    {
                        path: "user",
                        element: <User></User>
                    },
                    {
                        path: "menu",
                        element: <Menu></Menu>
                    },
                    {
                        path: "role",
                        element:<Role></Role>
                    },
                    {
                        path: "dept",
                        element: <Dept></Dept>
                    }]
            }
        ]
    },
    {
        path: "/",
        element: <AuthRoute></AuthRoute>,
        children: [
            {
                path: "/system",
                element: <Home/>,
                children: [
                    {
                        path: "",
                        element:<Navigate to="/system/user"></Navigate>
                    },
                    {
                        path: "user",
                        element: <User></User>
                    },
                ]
            }
        ]
    },
    {
        path: "/login",
        element: <Login/>,
    }

]);