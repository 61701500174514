import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxBackendVideoAuditDto, GodboxUserQuery, GodboxUserVo} from "../../entity/Godbox/User";

async function userGetPage(userQuery:GodboxUserQuery):Promise<Result<Page<GodboxUserVo>>> {
    let res = await api.post<Result<Page<GodboxUserVo>>>('/backend/godbox/user/page',{
        data:userQuery
    });
    requestTest(res)
    return res
}

async function userSetBan(dto:GodboxBackendVideoAuditDto):Promise<Result<Page<GodboxUserVo>>> {
    let res = await api.post<Result<Page<GodboxUserVo>>>('/backend/godbox/user/ban',{
        data:dto
    });
    requestTest(res)
    return res
}

export {
    userGetPage,
    userSetBan
}