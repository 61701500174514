import { configureStore  } from "@reduxjs/toolkit"
import useSlice from "./user/userSlice";

 const  store = configureStore({
    preloadedState:{
      user:{
          hasLogin:false,
          redirectUrl:"/system/dashboard"
      }
    },
    reducer: {
        user:useSlice,
    },
});
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch
export default store