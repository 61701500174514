import type {ParamsType, ProTableProps} from '@ant-design/pro-components';
import {BetaSchemaForm, ProTable} from '@ant-design/pro-components';
import React from 'react';
import {FormSchema} from "@ant-design/pro-form/es/components/SchemaForm/typing";


export type BaseTableProps<DataType,FormType, U, ValueType = 'text'> = {
    tableConfig:ProTableProps<DataType, U, ValueType>,
    formConfig:FormSchema<FormType,ValueType>
}

export const BaseTable = <
    DataType extends Record<string, any>,
    FormType extends Record<string, any> = DataType ,
    Params extends ParamsType = ParamsType,
    ValueType = 'text',
>( props: BaseTableProps<DataType,FormType, Params, ValueType>) => {
    return (
        <>
            <ProTable<DataType, Params, ValueType>
                {
                  ...props.tableConfig
                }
            />
            <BetaSchemaForm<FormType,ValueType>
                {
                  ...props.formConfig
                }
            />
        </>
    );
};