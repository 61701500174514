import {api} from "../Request";

import {LoginQuery, LoginUser, UserDto, UserQuery, UserVo} from "../../entity/System/User";
import {requestTest} from "../../utils";
import {Page, Result} from "../../entity";


async function userSaveUpdate(userDto:UserDto):Promise<boolean | undefined> {
    let res = await api.post<Result<boolean>>('/backend/user/saveUpdate',{
        data:userDto
    });
    return requestTest(res)
}
async function userGetPage(userQuery:UserQuery):Promise<Result<Page<UserVo>>> {
    let res = await api.post<Result<Page<UserVo>>>('/backend/user/page',{
        data:userQuery
    });
    requestTest(res)
    return res
}
async function userDelete(id:number):Promise<boolean | undefined>{
    let res = await api.get<Result<boolean>>('/backend/user/delete',{
        params:{
            id
        }
    });
    return requestTest(res)
}
async function login(loginQuery:LoginQuery):Promise<Result<LoginUser>> {
    let res = await api.post<Result<LoginUser>>("/backend/user/login",{
        data:loginQuery
    });
    requestTest(res)
    return res
}
async function userResetPwd(id:number):Promise<boolean | undefined>{
    let res = await api.get<Result<boolean>>('/backend/user/resetPassword',{
        params:{
            id
        }
    });
    return requestTest(res)
}


export {
    userSaveUpdate,
    userGetPage,
    userDelete,
    userResetPwd,
    login
}