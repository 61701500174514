import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxVideoAuditDto} from "../../entity/Godbox/VideoAudit";


async function getVideoAuditPage(menuDto: GodboxVideoAuditDto):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/video-audit/page',{
        data:menuDto
    });
    requestTest(res)
    return res
}
async function updateVideoAuditStatus(menuDto: GodboxVideoAuditDto):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/video-audit/update-status',{
        data:menuDto
    });
    return requestTest(res)
}

async function delVideoAudit(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/video-audit/delete',{
        data:{
            id
        }
    });
    return requestTest(res)
}

export {
    getVideoAuditPage,
    updateVideoAuditStatus,
    delVideoAudit
}