import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxVideoAuditDto} from "../../entity/Godbox/VideoAudit";
import {GodboxPromotionDto, GodboxPromotionQuery} from "../../entity/Godbox/Promotion";


async function getPromotionPage(query:GodboxPromotionQuery):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/promotion/page',{
        data:query
    });
    requestTest(res)
    return res
}
async function saveOrUpdatePromotion(dto:GodboxPromotionDto):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/promotion/saveOrUpdate',{
        data:dto
    });
    requestTest(res)
    return res
}

async function delPromotion(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/promotion/delete',{
        data:{
            id
        }
    });
    return requestTest(res)
}


export {
    getPromotionPage,
    saveOrUpdatePromotion,
    delPromotion,
}