import React, {useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {UserDto, UserVo} from "../../../entity/System/User";

import {userSaveUpdate} from "../../../apis/System/UserApi";
import {userGetPage, userSetBan} from "../../../apis/Godbox/UserApi";
import {formColumns, getTableColumns, startData} from "./UserConfig";
import {Status} from "../../../entity";

export default function GodboxUser (){

    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    const [modelShow, setModelShow] = useState(false)
    return (
        <div>
            <BaseTable<UserVo,UserDto>
                tableConfig = {{
                    columns:getTableColumns({
                    },(record)=>{
                        let status:Status;
                        if (record.status === "SHOW"){
                            status = "HIDDEN"
                        }else{
                            status = "SHOW"
                        }
                        if(record.status === "SHOW"){
                            return [
                                <Button danger  onClick={ async _ =>{
                                    await userSetBan({
                                        id:record.id,
                                        status:status as Status,
                                    })
                                    notification.success({
                                        message:"封禁成功"
                                    })
                                    actionRef.current?.reload()
                                }}>封禁</Button>
                            ]
                        }else {
                            return [
                                <Button   onClick={ async _ =>{
                                    await userSetBan({
                                        id:record.id,
                                        status:status as Status,
                                    })
                                    notification.success({
                                        message:"解封成功"
                                    })
                                    actionRef.current?.reload()
                                }}>解封</Button>
                            ]
                        }

                    }),
                    cardBordered:true,
                    rowKey:"id",
                    actionRef:actionRef,
                    request:async (params, sort, filter) => {
                        console.log(sort, filter,params)
                        const result =  await userGetPage({
                            page: params.current,
                            size: params.pageSize,
                            phone:params.phone,
                            mail:params.mail,
                            nickName:params.nickName,
                        })
                        console.log(result)
                        return {
                            data: result.data?.list ,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: result.success,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: result.data?.total,
                        }
                    },
                    pagination:{
                        pageSize: 5,
                    },
                    headerTitle:"用户数据"
                }}
                formConfig={{
                    form:form,
                    initialValues:startData,
                    title:"新建用户",
                    grid:true,
                    layoutType:"ModalForm",
                    open:modelShow,
                    onOpenChange:setModelShow,
                    columns:formColumns,
                    onFinish: async (formData)=>{

                        formData.status = formData.status?"SHOW":"HIDDEN"
                        await userSaveUpdate(formData)
                        actionRef.current?.reload()
                        setModelShow(false)
                        return true
                    },
                    modalProps:{
                        destroyOnClose:true,
                        mask:true
                    }
                }}
            ></BaseTable>
        </div>
    )
}