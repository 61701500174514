import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Tag, TreeSelect} from "antd";
import React from "react";
import {getDeptList} from "../../../apis/System/DeptApi";
import {getMenuList} from "../../../apis/System/MenuApi";
import {buildDeptSelect, buildMenuSelect, getBaseTableColumns} from "../../../utils";
import {tableConfig} from "../../../entity";

const { SHOW_ALL } = TreeSelect;

export function getTableColumns(fns:tableConfig):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 92,
        },
        {
            title: '角色ID',
            dataIndex: 'id',
            hideInSearch:true,
        },
        {
            title: '角色名称',
            dataIndex: 'roleName',
            hideInSearch:true,
        },
        {
            title: '数据权限',
            dataIndex: 'dataScope',
            hideInSearch:true,
            render:(_, record)=>{
                if(record.dataScope==='ALL'){
                    return <Tag>全部</Tag>
                }else if(record.dataScope==='SELF_LEVEL'){
                    return <Tag>本级</Tag>
                }else {
                    return <Tag>自定义</Tag>
                }
            }
        },
        {
            title: '备注',
            dataIndex: 'remark',
            hideInSearch:true,
        },
        ...getBaseTableColumns(fns)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        title: '角色名称',
        dataIndex: 'roleName',
        colProps: {
            span: 12
        },
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: '请输入部门名',
                },
            ],
        }
    },
    {
        title: '数据范围',
        dataIndex: 'dataScope',
        colProps: {
            span: 12
        },
        formItemProps: {
            rules: [],
        },
        valueEnum:{
            ALL: {
                text: '全部',
                status: 'ALL',
            },
            SELF_LEVEL: {
                text: '本级',
                status: 'SELF_LEVEL',
            },
            SELF_CUSTOM: {
                text: '自定义',
                status: 'SELF_CUSTOM',
            },
        }
    },
    {
        title: '菜单分配',
        dataIndex: 'menuIds',
        valueType: 'treeSelect',
        colProps: {
            span: 24
        },
        fieldProps:{
            placeholder:"请选择菜单",
            treeCheckable: true,
            showCheckedStrategy: SHOW_ALL,
            treeCheckStrictly:true,
            labelInValue:false
        },
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: '请设置数据权限',
                },
            ],
        },
        request:async (params,props) => {
            const result = await getMenuList({})
            return buildMenuSelect(result.data||[])
        }
    },
    {
        valueType: 'dependency',
        name:['dataScope'],
        columns:({ dataScope }) => {
            return dataScope === 'SELF_CUSTOM'
                ? [
                    {
                        title: '数据权限',
                        dataIndex: 'deptIds',
                        valueType: 'treeSelect',
                        colProps: {
                            span: 24
                        },
                        fieldProps:{
                            placeholder:"请选择数据权限",
                            treeCheckable: true,
                            showCheckedStrategy: SHOW_ALL,
                            treeCheckStrictly:true,
                            labelInValue:false
                        },
                        formItemProps: {
                            rules: [
                                {
                                    required: true,
                                    message: '请设置数据权限',
                                },
                            ],
                        },
                        request:async (params,props) => {
                           const result = await getDeptList({})
                           return buildDeptSelect(result.data||[])
                        }
                    },
                ]
                : [];
        },
    },
    {
        title: '备注',
        dataIndex: 'remark',
        colProps: {
            span: 24
        },
        fieldProps:{
            placeholder:"请填写备注"
        },
        valueType:'textarea'
    },
    {
        title: '状态',
        dataIndex: 'status',
        colProps: {
            offset:22,
            span: 2
        },
        valueType:'switch'
    },
]


export const startData = {
    isTop:true,
    status:true
}