import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Radio, RadioChangeEvent} from "antd";
import React from "react";
import {getDeptList} from "../../../apis/System/DeptApi";
import {RequestOptionsType} from "@ant-design/pro-utils/es/typing";
import {MenuVo} from "../../../entity/System/Menu";

import {getBaseTableColumns} from "../../../utils";
import {tableConfig} from "../../../entity";

function buildDeptSelect(menus:MenuVo[]): RequestOptionsType[]{
    if(menus.length===0){
        return []
    }
    return menus.map(menu=>{
        let  children = [] as RequestOptionsType[]
        if(menu.children){
            children  = buildDeptSelect(menu.children)
        }
        return {
            title: menu.name,
            value: menu.id,
            children: children
        }
    })

}

export function getTableColumns(fns:tableConfig):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 92,
        },
        {
            title: '部门ID',
            dataIndex: 'id',
            hideInSearch:true,
        },
        {
            title: '部门名称',
            dataIndex: 'name',
        },
        ...getBaseTableColumns(fns)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        title: '部门名称',
        dataIndex: 'name',
        colProps: {
            span: 12
        },
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: '请输入部门名',
                },
            ],
        }
    },
    {
        title: '部门排序',
        dataIndex: 'sort',
        colProps: {
            span: 12
        },
        formItemProps: {
            rules: [],
        }
    },
    {
        title: '状态',
        dataIndex: 'status',
        colProps: {
            span: 6
        },
        valueType:'switch'
    },
    {
        title: '顶级部门',
        dataIndex: 'isTop',
        // valueType:'',
        colProps: {
            span: 6
        },
        renderFormItem:(schema,config,form)=>{
            const onChange = (e:RadioChangeEvent)=>{
                form.setFieldValue("isTop",e.target.value)
            }
            return (
                <Radio.Group onChange={onChange} value={form.getFieldValue("isTop")}>
                    <Radio value={true}>是</Radio>
                    <Radio value={false}>否</Radio>
                </Radio.Group>
            )
        }
    },
    {
        valueType: 'dependency',
        name:['isTop'],
        columns:({ isTop }) => {
            return !isTop
                ? [
                    {
                        title: '上级部门',
                        dataIndex: 'parentId',
                        valueType: 'treeSelect',
                        colProps: {
                            span: 12
                        },
                        formItemProps: {
                            rules: [
                                {
                                    required: true,
                                    message: '请设置上级部门',
                                },
                            ],
                        },
                        request:async (params,props) => {
                           const result = await getDeptList({})
                           return buildDeptSelect(result.data||[])
                        }
                    },
                ]
                : [];
        },
    }
]


export const startData = {
    isTop:true,
    status:true
}