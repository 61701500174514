import React from "react";
import {Provider} from "react-redux";
import store from "./store";
import {RouterProvider} from "react-router-dom";
import router from "./router";
import {IconProvider,DEFAULT_ICON_CONFIGS} from "@icon-park/react";

const App: React.FC = () => {

  return (

          <Provider store={store}>
              <IconProvider value={{...DEFAULT_ICON_CONFIGS,size:'30px'}}>
                  <RouterProvider router={router} />
              </IconProvider>
          </Provider>
  );
};
export default App;