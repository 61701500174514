import React, {useEffect} from "react";
import "./Login.scss"
import {AntDesignOutlined} from '@ant-design/icons';
import {Avatar, Button, Form, Input} from "antd";
import {loginBack} from "../../store/user/userSlice";
import {useAppDispatch, useAppSelector} from "../../hook/storeHook";
import {useNavigate} from "react-router-dom";
import {LoginQuery} from "../../entity/System/User";


type FieldType = {
    phone?: string;
    password?: string;
    remember?: string;
};
export default function Login (){
    const dispatch = useAppDispatch()
    const user = useAppSelector((state) => state.user)
    const navigate = useNavigate()
    //当location发生变化即调用，loadData用于加载页面数据
    useEffect(()=>{
        // redux使用的总体流程是
        // 使用dispatch 进行 redux 的数据更新 然后 页面中使用useEffect 进行数据监听 进行后续的数据处理
        if (user.hasLogin) {
            console.log(user)
            navigate(user.redirectUrl,{replace:true}); // 跳转到用户资料页面
        }
    }, [navigate,user])
    const onFinish = (values: any) => {
        dispatch(loginBack({phone:values.phone,password:values.password,navigate} as LoginQuery))
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
     return (
         <div className="login">
             <div className="login-left">
             </div>
             <div className="login-right">
                 <Avatar
                     size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }}
                     icon={<AntDesignOutlined />}
                 />
                 <div className="login-anther-login"></div>
                 <Form
                     name="basic"
                     labelCol={{span: 6}}
                     wrapperCol={{span: 18}}
                     style={{maxWidth: 600}}
                     initialValues={{remember: true}}
                     onFinish={onFinish}
                     onFinishFailed={onFinishFailed}
                     autoComplete="off"
                 >
                     <Form.Item<FieldType>
                         label="电话"
                         name="phone"
                         rules={[{required: true, message: '请输入电话'}]}
                     >
                         <Input/>
                     </Form.Item>

                     <Form.Item<FieldType>
                         label="密码"
                         name="password"
                         rules={[{required: true, message: '请输入密码!'}]}
                     >
                         <Input.Password/>
                     </Form.Item>

                     {/*<Form.Item<FieldType>*/}
                     {/*    name="remember"*/}
                     {/*    valuePropName="checked"*/}
                     {/*    wrapperCol={{ offset: 8, span: 16 }}*/}
                     {/*>*/}
                     {/*    <Checkbox>Remember me</Checkbox>*/}
                     {/*</Form.Item>*/}

                     <Form.Item wrapperCol={{offset: 6, span: 18}}>
                         <Button className="login-btn" type="primary" htmlType="submit" block>
                             登录
                         </Button>
                     </Form.Item>
                 </Form>
             </div>
         </div>
     )
}
