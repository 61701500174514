import React, {useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {PlusOutlined} from "@ant-design/icons";
import {formColumns, getTableColumns, startData} from "./DataSourceConfig";
import {GodboxDataSourceDto, GodboxDataSourceQuery} from "../../../entity/Godbox/DataSource";
import {delDataSource, getDataSourcePage, saveOrUpdateDataSource} from "../../../apis/Godbox/DataSourceApi";

export default function GobBoxDataSource () {

    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    const [modelShow, setModelShow] = useState(false)
    return (
        <div>
            <BaseTable<GodboxDataSourceQuery, GodboxDataSourceDto>
                tableConfig={{

                    columns: getTableColumns({
                        hideTimeSearch: {
                            hideCreateTime: true,
                            hideUpdateTime: true,
                        },
                        editFn: (record) => {
                            form.setFieldsValue(record)
                            if(record.status==="SHOW"){
                                form.setFieldValue("status",true)
                            }else{
                                form.setFieldValue("status",false)
                            }
                            setModelShow(true)
                        },
                        deleteFn: async (record) => {
                            console.log(record)
                            await delDataSource(record.id)
                            actionRef.current?.reload()
                            notification.success({
                                message: "删除成功"
                            })
                        },
                    },(record) => [
                        // <Button onClick={ async _ =>{
                        //     await setActive(record.id)
                        //     notification.success({
                        //         message:"设为最新版本成功"
                        //     })
                        //     actionRef.current?.reload()
                        // }}>设为最新</Button>
                    ]),
                    cardBordered: true,
                    rowKey: "DataSourceUrl",
                    actionRef: actionRef,
                    toolBarRender: () => [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setModelShow(true)
                            }}
                            type="primary"
                        >
                            新建
                        </Button>
                    ],
                    request: async (params, sort, filter) => {
                        console.log(sort, filter, params)
                        const result = await getDataSourcePage({
                            page: params.current,
                            size: params.pageSize,
                        })
                        return {
                            data: result.data?.list,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: result.success,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: result.data?.total,
                        }
                    },
                    pagination: {
                        pageSize: 5,
                    },
                    headerTitle: "版本数据"
                }}
                formConfig={{
                    form: form,
                    width: 500,
                    initialValues: startData,
                    title: "新建版本",
                    grid: true,
                    layoutType: "ModalForm",
                    open: modelShow,
                    onOpenChange: setModelShow,
                    columns: formColumns,
                    onFinish: async (data) => {
                        data.status = data.status?"SHOW":"HIDDEN"
                        await saveOrUpdateDataSource(data)
                        setModelShow(false)
                        actionRef.current?.reload()
                    },
                    modalProps: {
                        destroyOnClose: true,
                        mask: true
                    }
                }}
            ></BaseTable>
        </div>
    )
}

