import COS, {UploadFileResult} from 'cos-js-sdk-v5';
import {S3Client,} from "@aws-sdk/client-s3";
import {api} from "../apis/Request";
import {Result} from "../entity";
import {requestTest} from "./index";
import {Upload} from '@aws-sdk/lib-storage';
import {RcFile} from "antd/es/upload";

export interface StsDto {
    tmpSecretId: string;
    tmpSecretKey: string;
    sessionToken: string;
    startTime: number; // 使用 number 类型表示时间戳
    expiredTime: number; // 使用 number 类型表示时间戳
}

class CosUtil {
    private static _instance: CosUtil; // 存储唯一实例
    private static _cos: COS; // 你可以根据需要定义 _cos 的类型
    private static _s3:S3Client = new S3Client({
        region: "auto",
        endpoint: `https://sqhzstore.org`,
        credentials: {
            accessKeyId: "c6176023764a49bbcb2b9ee29221bba5",
            secretAccessKey: "2be3f55a93e94c6a929cbae1c86b178a12a291865dc4669ae6d4d03b2a448dc6",
        },
    });
    // 私有构造函数，防止外部实例化
    private constructor() {}
    // 公共静态方法获取单例实例
    public static getInstance(): CosUtil {
        if (!this._instance) {
            this._instance = new CosUtil();
        }
        return this._instance;
    }
    // 初始化方法
    public static async init() {
        let res = await api.post<Result<StsDto>>('/backend/cos/getSts');
        let stsDto = requestTest(res);

        this._cos = new COS({
            SecretId: stsDto?.tmpSecretId, // sts服务下发的临时 secretId
            SecretKey: stsDto?.tmpSecretKey, // sts服务下发的临时 secretKey
            SecurityToken: stsDto?.sessionToken, // sts服务下发的临时 SessionToken
            StartTime: stsDto?.startTime, // 建议传入服务端时间
            ExpiredTime: stsDto?.expiredTime, // 临时密钥过期时间
        });
    }
    public static async uploadFile(config:Pick<COS.UploadFileParams, 'Body'|'Key'|'onTaskReady'|'onProgress'|'Headers'>){
        return new Promise<UploadFileResult>(async (resolve, reject) => {
            this._cos.uploadFile({
                Bucket: 'godbox-1259347022', // 填入您自己的存储桶，必须字段
                Region: 'ap-guangzhou',  // 存储桶所在地域，例如ap-beijing，必须字段
                Key: config.Key,  // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段
                Body: config.Body, // 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象
                SliceSize: 1024 * 1024 * 5,     // 触发分块上传的阈值，超过5MB使用分块上传，非必须
                onTaskReady: config.onTaskReady,
                onProgress: config.onProgress,
                // 支持自定义headers 非必须
                Headers: config.Headers,
            }, function(err, data) {
                if (err) {
                    console.log('上传失败', err);
                    reject(err);
                } else {
                    console.log('上传成功', data);
                    resolve(data)
                }
            });
        })

    }

    public static async uploadS3File(file: RcFile,key:string,onProcess:(percent:number)=>void) {
        try {
            const upload = new Upload({
                client: this._s3,
                params: {
                    Bucket: key,
                    Key: file.name,
                    Body: file,
                    ContentType: file.type,
                },
            });
            // 监听上传进度
            upload.on("httpUploadProgress", (progress) => {
                let percent = (progress.loaded! / progress.total!) * 100
                onProcess(percent)
            });
            return await upload.done();
        } catch (error) {
            throw error;
        }
    }

    public static async uploadWorker(file:File,key:string,onProcess:(percent:number)=>void){
        return new Promise((resolve,reject)=>{
            const formData = new FormData();
            formData.append('file', file);
            const xhr = new XMLHttpRequest();
            xhr.open('POST', `https://sqhzstore.org/${key}`, true);
            xhr.upload.onprogress = function(event) {
                if (event.lengthComputable) {
                    const percentComplete = (event.loaded / event.total) * 100;
                    onProcess(Math.floor(percentComplete))
                }
            };
            xhr.onload = function() {
                if (xhr.status >= 200 && xhr.status < 300) {
                    resolve(xhr.response);
                } else {
                    resolve(xhr.responseText);
                }
            };
            xhr.onerror = function(e) {
                reject(e);
            };

            xhr.send(formData);
        })

    }
}







export default CosUtil;