import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "./assets/css/theme.scss"
import reportWebVitals from './reportWebVitals';
import App from "./App";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
 <App/>
);

// 做性能分析
reportWebVitals();
