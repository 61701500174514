import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {LoginQuery, LoginUser} from "../../entity/System/User";
import {login} from "../../apis/System/UserApi";
import {SESSION_TOKEN_ITEM} from "../../utils/constants";
import {MenuVo} from "../../entity/System/Menu";
import CosUtil from "../../utils/cos";

export const loginBack = createAsyncThunk("user/login",async (loginQuery:LoginQuery)=>{
     return  login(loginQuery)
});

const initialState:LoginUser&{hasLogin:boolean,redirectUrl:string} = {
    hasLogin:false,
    redirectUrl:"/system/dashboard"
}
function getRedirectUrl(menus:MenuVo[]|undefined):string{
    if(!menus){
        return ""
    }
    let firstMenu = menus[0]
    if(!firstMenu){
       return ""
    }
    if(firstMenu.children){
        return getRedirectUrl(firstMenu.children)
    }
    return firstMenu.url||""
}
export const useSlice = createSlice({
    name:"user",
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(loginBack.fulfilled,(state,action)=>{
            if(action.payload.success){
                console.log("action",action)
                Object.assign(state,action.payload.data)
                sessionStorage.setItem(SESSION_TOKEN_ITEM,state.token||"")
                state.hasLogin = true
                state.redirectUrl = getRedirectUrl(action.payload.data?.menus)||state.redirectUrl
                console.log(state)
                CosUtil.init()
            }

        })
    }
})
// 导出加减的方法
export default useSlice.reducer;
