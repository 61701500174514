import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxVideoAuditDto} from "../../entity/Godbox/VideoAudit";
import {GodboxDataSourceDto, GodboxDataSourceQuery} from "../../entity/Godbox/DataSource";


async function getDataSourcePage(query:GodboxDataSourceQuery):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/datasource/page',{
        data:query
    });
    requestTest(res)
    return res
}
async function saveOrUpdateDataSource(dto:GodboxDataSourceDto):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/datasource/saveOrUpdate',{
        data:dto
    });
    requestTest(res)
    return res
}

async function delDataSource(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/datasource/delete',{
        data:{
            id
        }
    });
    return requestTest(res)
}


export {
    getDataSourcePage,
    saveOrUpdateDataSource,
    delDataSource,
}