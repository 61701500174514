import {api} from "../Request";


import {requestTest} from "../../utils";
import {RoleDto, RoleVo} from "../../entity/System/Role";
import {Page, Result} from "../../entity";

async function getRoleList(roleDto: RoleDto):Promise<Result<Page<RoleVo>>> {
    let res = await api.post<Result<Page<RoleVo>>>('/backend/role/page',{
        data:roleDto
    });
    requestTest(res)
    return res
}
async function delRole(id: Number):Promise<Boolean|undefined>{
    let res = await api.get<Result<Boolean>>('/backend/role/delete',{
        params:{
            id
        }
    });
    return requestTest(res)
}

async function addRole(roleDto: RoleDto):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/backend/role/saveUpdate',{
        data:roleDto
    });
    return requestTest(res)
}
export {
    getRoleList,
    delRole,
    addRole
}