import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxVideoAuditDto} from "../../entity/Godbox/VideoAudit";
import {GodboxDownloadDto, GodboxDownloadQuery} from "../../entity/Godbox/Download";


async function getDownloadPage(query:GodboxDownloadQuery):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/download/page',{
        data:query
    });
    requestTest(res)
    return res
}
async function saveOrUpdateDownload(dto:GodboxDownloadDto):Promise<Result<Page<GodboxVideoAuditDto>>> {
    let res = await api.post<Result<Page<GodboxVideoAuditDto>>>('/godbox/download/saveOrUpdate',{
        data:dto
    });
    requestTest(res)
    return res
}

async function delDownload(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/download/delete',{
        data:{
            id
        }
    });
    return requestTest(res)
}
async function setActive(id: Number):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/download/set-active',{
        data:{
            id
        }
    });
    return requestTest(res)
}

export {
    getDownloadPage,
    saveOrUpdateDownload,
    delDownload,
    setActive
}