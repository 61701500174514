import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import React from "react";
import {getBaseTableColumns} from "../../../utils";
import {tableConfig} from "../../../entity";
import {Space, Tag} from "antd";
const valueEnum = {
    NORMAL: {
        text: '普通热搜',
        status: 'NORMAL',
    },
    URL: {
        text: '链接热搜',
        status: 'URL',
    },
};

export function getTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => []):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '关键词',
            dataIndex: 'keyword',
        },
        {
            title: '商品链接',
            dataIndex: 'url',
            search: false,
        },
        {
            title: '状态',
            dataIndex: 'type',
            search: false,
            render(_, record) {
                return (
                    <Space>
                        {
                            record.type === 'NORMAL'?
                                <Tag color="success">普通热搜</Tag>:
                                <Tag color="error">商品链接</Tag>
                        }
                    </Space>
                )
            }
        },
        ...getBaseTableColumns(fns,reactElementFn,false,true,false)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        valueType:"divider"
    },
    {
        title:"基础信息",
        valueType:"group",
        columns:[
            {
                title: '关键词',
                dataIndex: 'keyword',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入关键词',
                        },
                    ],
                }
            },
            {
                title: '类型',
                dataIndex: 'type',
                colProps: {
                    span: 12
                },
                valueType:"radio",
                valueEnum
            },
            {
                valueType: 'dependency',
                name:['type'],
                columns:(config)=>{

                    console.log(config)
                    if(config.type === 'URL') {
                        return [
                            {
                                title: '链接',
                                dataIndex: 'url',
                                colProps: {
                                    span: 24
                                },
                                formItemProps: {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入链接',
                                        },
                                    ],
                                }
                            },

                        ]
                    }else{
                        return []
                    }
                }
            }
        ]
    },
]
export const startData = {

}