import React, {useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";

import {saveUpdateKeyword} from "../../../apis/Godbox/KeywordApi";
import {PlusOutlined} from "@ant-design/icons";
import {formColumns, getTableColumns, startData} from "./VideoAuditConfig";
import {delVideoAudit, getVideoAuditPage, updateVideoAuditStatus} from "../../../apis/Godbox/VideoAuditApi";
import {VideoAuditStatus} from "../../../entity";
import {GodboxVideoAuditDto} from "../../../entity/Godbox/VideoAudit";

export default function GodboxVideoAudit () {

    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    const [modelShow, setModelShow] = useState(false)
    return (
        <div>
            <BaseTable<GodboxVideoAuditDto, GodboxVideoAuditDto>
                tableConfig={{
                    columns: getTableColumns({
                        hideTimeSearch: {
                            hideCreateTime: true,
                            hideUpdateTime: true,
                        },
                        deleteFn: async (record) => {
                            console.log(record)
                            await delVideoAudit(record.id)
                            actionRef.current?.reload()
                            notification.success({
                                message: "删除成功"
                            })
                        },
                    }, (record) => {
                        let status:VideoAuditStatus;
                        if (record.status === "REJECT"){
                            status = "PASS"
                        }else{
                            status = "REJECT"
                        }
                        if(record.status === "REJECT"){
                            return [
                                <Button danger  onClick={ async _ =>{
                                    await updateVideoAuditStatus({
                                        id:record.id,
                                        status:status as VideoAuditStatus,
                                    })
                                    notification.success({
                                        message:"解封成功"
                                    })
                                    actionRef.current?.reload()
                                }}>解封</Button>
                            ]
                        }else {
                            return [
                                <Button   onClick={ async _ =>{
                                    await updateVideoAuditStatus({
                                        id:record.id,
                                        status:status as VideoAuditStatus,
                                    })
                                    notification.success({
                                        message:"封禁成功"
                                    })
                                    actionRef.current?.reload()
                                }}>封禁</Button>
                            ]
                        }

                    }),
                    cardBordered: true,
                    rowKey: "id",
                    actionRef: actionRef,
                    toolBarRender: () => [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setModelShow(true)
                            }}
                            type="primary"
                        >
                            新建
                        </Button>
                    ],
                    request: async (params, sort, filter) => {
                        console.log(sort, filter, params)
                        const result = await getVideoAuditPage({
                            page: params.current,
                            size: params.pageSize,
                            videoName: params.videoName
                        })
                        return {
                            data: result.data?.list,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: result.success,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: result.data?.total,
                        }
                    },
                    pagination: {
                        pageSize: 5,
                    },
                    headerTitle: "用户数据"
                }}
                formConfig={{
                    form: form,
                    width: 500,
                    initialValues: startData,
                    title: "新建用户",
                    grid: true,
                    layoutType: "ModalForm",
                    open: modelShow,
                    onOpenChange: setModelShow,
                    columns: formColumns,
                    onFinish: async (formData) => {

                        await saveUpdateKeyword(formData)
                        actionRef.current?.reload()
                        setModelShow(false)
                        return true
                    },
                    modalProps: {
                        destroyOnClose: true,
                        mask: true
                    }
                }}
            ></BaseTable>
        </div>
    )
}