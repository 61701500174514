import {api} from "../Request";


import {requestTest} from "../../utils";
import {DeptDto, DeptVo} from "../../entity/System/Dept";
import {Result} from "../../entity";

async function getDeptList(deptDto: DeptDto):Promise<Result<DeptVo[]>> {
    let res = await api.post<Result<DeptVo[]>>('/backend/dept/list',{
        data:deptDto
    });
    requestTest(res)
    return res
}
async function addDept(deptDto: DeptDto):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/backend/dept/saveUpdate',{
        data:deptDto
    });
   return requestTest(res)
}

async function delDept(id: Number):Promise<Boolean|undefined>{
    let res = await api.get<Result<Boolean>>('/backend/dept/delete',{
        params:{
            id
        }
    });
    return requestTest(res)
}

export {
    getDeptList,
    addDept,
    delDept
}