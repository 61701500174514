import {api} from "../Request";


import {requestTest} from "../../utils";
import {MenuDto, MenuVo} from "../../entity/System/Menu";
import {Result} from "../../entity";

async function getMenuList(menuDto: MenuDto):Promise<Result<MenuVo[]>> {
    let res = await api.post<Result<MenuVo[]>>('/backend/menu/list',{
        data:menuDto
    });
    requestTest(res)
    return res
}
async function addMenu(menuDto: MenuDto):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/backend/menu/saveUpdate',{
        data:menuDto
    });
    return requestTest(res)
}

async function delMenu(id: Number):Promise<Boolean|undefined>{
    let res = await api.get<Result<Boolean>>('/backend/menu/delete',{
        params:{
            id
        }
    });
    return requestTest(res)
}

export {
    getMenuList,
    addMenu,
    delMenu
}