import React, {useRef, useState} from "react";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {PlusOutlined} from '@ant-design/icons';
import {formColumns, getTableColumns} from "./UserConfig";
import {Button, Form, notification} from "antd";
import {UserDto, UserVo} from "../../../entity/System/User";
import {userDelete, userGetPage, userResetPwd, userSaveUpdate} from "../../../apis/System/UserApi";
import {ActionType} from "@ant-design/pro-components";
import {startData} from "../Dept/DeptConfig";


export default function Menu (){
    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    const [modelShow, setModelShow] = useState(false)
    return (
        <div>
           <BaseTable<UserVo,UserDto>
             tableConfig = {{
                 columns:getTableColumns({
                     editFn:(record)=>{
                         form.setFieldsValue(record)
                         const deptId = record.dept?.id
                         const roleId = record.role?.id
                         form.setFieldValue("departmentId",deptId)
                         form.setFieldValue("roleId",roleId)
                         console.log(record)
                         if(record.status==="SHOW"){
                             form.setFieldValue("status",true)
                         }else{
                             form.setFieldValue("status",false)
                         }
                         setModelShow(true)
                     },
                     deleteFn:async (record)=>{
                          console.log(record)
                         await userDelete(record.id)
                         actionRef.current?.reload()
                         notification.success({
                             message:"删除成功"
                         })
                     },
                 },(record)=>{
                    return [
                        <Button onClick={ async _ =>{

                           await  userResetPwd(record.id)
                            notification.success({
                                message:"重置密码成功"
                            })
                        }}>重置密码</Button>
                    ]
                 }),
                 cardBordered:true,
                 rowKey:"id",
                 actionRef:actionRef,
                 toolBarRender:()=> [
                     <Button
                         key="button"
                         icon={<PlusOutlined />}
                         onClick={() => {
                             setModelShow(true)
                         }}
                         type="primary"
                     >
                         新建
                     </Button>
                 ],
                 request:async (params, sort, filter) => {
                     console.log(sort, filter,params)
                     const result =  await userGetPage({

                     })
                     console.log(result)
                     return {
                         data: result.data?.list ,
                         // success 请返回 true，
                         // 不然 table 会停止解析数据，即使有数据
                         success: result.success,
                         // 不传会使用 data 的长度，如果是分页一定要传
                         total: result.data?.total,
                     }
                 },
                 pagination:{
                     pageSize: 5,
                 },
                 headerTitle:"用户数据"
             }}
             formConfig={{
                 form:form,
                 initialValues:startData,
                 title:"新建用户",
                 grid:true,
                 layoutType:"ModalForm",
                 open:modelShow,
                 onOpenChange:setModelShow,
                 columns:formColumns,
                 onFinish: async (formData)=>{

                     formData.status = formData.status?"SHOW":"HIDDEN"
                     await userSaveUpdate(formData)
                     actionRef.current?.reload()
                     setModelShow(false)
                     return true
                 },
                 modalProps:{
                     destroyOnClose:true,
                     mask:true
                 }
             }}
           ></BaseTable>
        </div>
    )
}