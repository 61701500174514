import {tableConfig} from "../../../entity";
import React from "react";
import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Space, Tag} from "antd";
import {getBaseTableColumns} from "../../../utils";

export function getTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => []):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '审核编号',
            dataIndex: 'id',
            search: false,
        },
        {
            title: '视频名字',
            dataIndex: 'videoName',
        },
        {
            title: '爬虫',
            dataIndex: 'crawler',
        },
        {
            title: '视频详情地址',
            dataIndex: 'videoDetailUrl',
            search: false,
        },
        {
            title: '审核状态',
            dataIndex: 'status',
            search: false,
            render(_, record) {
                console.log(record)
                if(record.status === 'UN_AUDIT'){
                    return (
                        <Space>
                            <Tag color="success">未审核</Tag>
                        </Space>
                    )
                }else if(record.status === 'PASS'){
                    return (
                        <Space>
                            <Tag color="success">已通过</Tag>
                        </Space>
                    )
                }else {
                    return (
                        <Space>
                            <Tag color="error">已封禁</Tag>
                        </Space>
                    )
                }

            }
        },
        ...getBaseTableColumns(fns,reactElementFn,false,true,false)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        valueType:"divider"
    },
    {
        title:"基础信息",
        valueType:"group",
        columns:[
            {
                title: '用户名',
                dataIndex: 'name',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入用户名',
                        },
                    ],
                }
            },
            {
                title: '电话',
                dataIndex: 'phone',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入电话',
                        },
                    ],
                }
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入邮箱',
                        },
                    ],
                }
            }
        ]
    },
]
export const startData = {
    status:true
}