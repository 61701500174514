
import {Button, DatePicker, notification, Popconfirm, Space, Tag} from "antd";
import type {ProColumns} from "@ant-design/pro-components";
import dayjs from "dayjs";
import React from "react";
import {MenuVo} from "../entity/System/Menu";
import {RequestOptionsType} from "@ant-design/pro-utils/es/typing";
import {RoleVo} from "../entity/System/Role";
import {Result, tableConfig} from "../entity";


export function getBaseTableConfig(){
    return
}

export  function requestTest<T>(result:Result<T>):T|undefined{
    if(!result.success||result.code!==200){
        notification.error({
            duration:2000,
            message:result.msg
        })
    }
    return result.data
}

export function getBaseTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => [],hasStatus:Boolean = true,hasOption:Boolean = true,hasTime:boolean = true):ProColumns[]{
    let hideCreated = true,hideUpdate = true
    if(typeof fns.hideTimeSearch === "boolean"){
        hideCreated = fns.hideTimeSearch as boolean
        hideUpdate = fns.hideTimeSearch as boolean
    }else if(typeof fns.hideTimeSearch === "object"){
        hideCreated = typeof fns.hideTimeSearch.hideCreateTime ==='boolean'?fns.hideTimeSearch.hideCreateTime :true
        hideUpdate = typeof fns.hideTimeSearch.hideUpdateTime ==='boolean' ?fns.hideTimeSearch.hideUpdateTime:true
    }
    console.log(hideCreated,hideUpdate)
    let statusList:ProColumns[] = []
    let optionList:ProColumns[] = []
    let timeList:ProColumns[] = []
    if(hasStatus){
        statusList.push({
            title: '状态',
            dataIndex: 'status',
            search: false,
            render(_, record) {
                return (
                    <Space>
                        {
                            record.status === 'SHOW'?
                                <Tag color="success">激活</Tag>:
                                <Tag color="error">禁用</Tag>
                        }
                    </Space>
                )
            }
        })
    }
    if(hasOption){
        optionList.push({
            title: '操作',
            valueType: 'option',
            key: 'option',
            fixed: 'right',
            render: (text, record, _, action) => [
                fns.editFn && typeof fns.editFn==="function"? <Button onClick={()=>{
                    if(fns.editFn && typeof fns.editFn==="function"){
                        fns.editFn(record)
                    }
                }}>编辑</Button>:"",
                fns.lookFn && typeof fns.lookFn==="function" ? <Button onClick={()=>{
                    if(fns.lookFn && typeof fns.lookFn==="function"){
                        fns.lookFn(record)
                    }
                }}>查看</Button>:"",
                fns.deleteFn && typeof fns.deleteFn==="function"?<Popconfirm
                    title='确定要删除吗？'
                    okText="Yes"
                    cancelText="No"
                    onConfirm={()=>{
                        if(fns.deleteFn && typeof fns.deleteFn==="function"){
                            fns.deleteFn(record)
                        }
                    }}
                >
                    <Button danger>删除</Button>
                </Popconfirm>:"",
                ...reactElementFn(record)
            ],
        })
    }
    if(hasTime){
        timeList.push({
            title: '创建时间',
            dataIndex: 'createdTime',
            hideInSearch:hideCreated,
            renderFormItem: () => {
                return (
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                    />
                );
            },
            render(_, record) {
                return (
                    <Space>
                        <div>{ dayjs(record.createdTime ).format("YYYY-MM-DD HH:mm:ss")}</div>
                    </Space>
                )
            }
        })
        timeList.push({
            title: '修改时间',
            dataIndex: 'updatedTime',
            hideInSearch:hideUpdate,
            renderFormItem: () => {
                return (
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                    />
                );
            },
            render(_, record) {
                return (
                    <Space>
                        <div>{ dayjs(record.updatedTime ).format("YYYY-MM-DD HH:mm:ss")}</div>
                    </Space>
                )
            }
        })
    }
    console.log(statusList,optionList)
    return [
        ...statusList,
        ...timeList,
        ...optionList,
    ]
}


export function buildDeptSelect(menus:MenuVo[]): RequestOptionsType[]{
    if(menus.length===0){
        return []
    }
    return menus.map(menu=>{
        let  children = [] as RequestOptionsType[]
        if(menu.children){
            children  = buildDeptSelect(menu.children)
        }
        return {
            title: menu.name,
            value: menu.id,
            children: children
        }
    })

}
export function buildMenuSelect(menus:MenuVo[]):RequestOptionsType[]{
    if(menus.length===0){
        return []
    }
    return menus.map(menu=>{
        let  children = [] as RequestOptionsType[]
        if(menu.children){
            children  = buildDeptSelect(menu.children)
        }
        return {
            title: menu.name,
            value: menu.id,
            children: children
        }
    })

}

export function buildRoleSelect(roles:RoleVo[]):RequestOptionsType[]{
    if(roles.length===0){
        return []
    }
    return roles.map(role=>{
        return {
            label: role.roleName,
            value: role.id
        }
    })

}