import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {DatePicker, Image, Space, Tag} from "antd";
import React from "react";
import {getBaseTableColumns} from "../../../utils";

import dayjs from "dayjs";
import {tableConfig} from "../../../entity";


export function getTableColumns(fns:tableConfig,reactElementFn:(record:any)=>React.ReactElement[] =(record) => []):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '头像',
            dataIndex: 'headImg',
            search: false,
            render(_, record) {
                return (
                    <Space>
                        <Image
                            width={50}
                            height={50}
                            style={{objectFit:"cover",borderRadius:25}}
                            src={record.headImg}
                        />
                    </Space>
                )
            }
        },
        {
            title: '昵称',
            dataIndex: 'nickName',
        },
        {
            title: '电话',
            dataIndex: 'phone',
        },
        {
            title: '邮箱',
            dataIndex: 'mail',

        },
        {
            title: '是否是会员',
            dataIndex: 'isVip',
            search: false,
        },
        {
            title: 'VIP截至时间',
            dataIndex: "vipEndTime",
            search: false,
            renderFormItem: () => {
                return (
                    <DatePicker
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime={{defaultValue: dayjs('00:00:00', 'HH:mm:ss')}}
                    />
                );
            },
            render(_, record) {
                if(record.vipEndTime){
                    return (
                        <Space>
                            <div>{ dayjs(record.vipEndTime ).format("YYYY-MM-DD HH:mm:ss")}</div>
                        </Space>
                    )
                }else{
                    return <div>-</div>
                }

            }
        },
        {
            title: '状态',
            dataIndex: 'status',
            search: false,
            render(_, record) {
                console.log(record)
                return (
                    <Space>
                        {
                            record.status === 'SHOW'?
                                <Tag color="success">正常</Tag>:
                                <Tag color="error">封禁</Tag>
                        }
                    </Space>
                )
            }
        },
        ...getBaseTableColumns(fns,reactElementFn,false,true,false)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        valueType:"divider"
    },
    {
        title:"基础信息",
        valueType:"group",
        columns:[
            {
                title: '用户名',
                dataIndex: 'name',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入用户名',
                        },
                    ],
                }
            },
            {
                title: '电话',
                dataIndex: 'phone',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入电话',
                        },
                    ],
                }
            },
            {
                title: '昵称',
                dataIndex: 'nickName',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '邮箱',
                dataIndex: 'email',
                colProps: {
                    span: 12
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入邮箱',
                        },
                    ],
                }
            }
        ]
    },
]
export const startData = {
    status:true
}