import type {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {Tag} from "antd";
import React from "react";
import {RequestOptionsType} from "@ant-design/pro-utils/es/typing";
import {MenuVo} from "../../../entity/System/Menu";

import {getBaseTableColumns} from "../../../utils";
import {getMenuList} from "../../../apis/System/MenuApi";
import IconPicker from "../../../components/IconPicker/IconPicker";
import {tableConfig} from "../../../entity";


function buildDeptSelect(menus:MenuVo[]): RequestOptionsType[]{
    if(menus.length===0){
        return []
    }
    return menus.map(menu=>{
        let  children = [] as RequestOptionsType[]
        if(menu.children){
            children  = buildDeptSelect(menu.children)
        }
        return {
            title: menu.name,
            value: menu.id,
            children: children
        }
    })

}

export function getTableColumns(fns:tableConfig):ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 92,
        },
        {
            title: '菜单ID',
            dataIndex: 'id',
            hideInSearch:true,
        },
        {
            title: '名称',
            dataIndex: 'name',
        },
        {
            title: '菜单ICON',
            dataIndex: 'icon',
            hideInSearch:true,
        },
        {
            title: '菜单类型',
            dataIndex: 'type',
            hideInSearch:true,
            render:(_,entity)=>{
                if(entity.type==='DIRECTORY'){
                    return <Tag>目录</Tag>
                }else if(entity.type==='MENU'){
                    return <Tag>菜单</Tag>
                }else{
                    return <Tag>按钮</Tag>
                }
            }
        },
        {
            title: '菜单URL',
            dataIndex: 'url',
            hideInSearch:true,
        },
        {
            title: '菜单权限标识',
            dataIndex: 'auth',
            hideInSearch:true,
        },
        {
            title: '菜单组件路径',
            dataIndex: 'component',
            hideInSearch:true,
        },
        ...getBaseTableColumns(fns)
    ];
}

export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex:"id",
        colProps: {
            span: 0
        },
        renderFormItem:()=>{
            return <div style={{display:"none"}}></div>
        }
    },
    {
        title: '菜单类型',
        dataIndex: 'type',
        valueType:'select',
        valueEnum:{
            DIRECTORY: {
                text: '目录',
                status: 'DIRECTORY',
            },
            MENU: {
                text: '菜单',
                status: 'MENU',
            },
            BUTTON: {
                text: '按钮',
                status: 'BUTTON',
            },
        },
        colProps: {
            span: 12
        },
        formItemProps: {
            rules: [],
        }
    },
    {
        valueType: 'dependency',
        name:['type'],
        columns:({type})=>{
           if(type==='DIRECTORY') {
               return [
                   {
                       title: '菜单名称',
                       dataIndex: 'name',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '菜单排序',
                       dataIndex: 'sort',
                       valueType:'digit',
                       colProps: {
                           span: 12
                       },
                       fieldProps:{
                           style:{
                               width:"100%"
                           }
                       },
                       colSize:12,
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '权限标识',
                       dataIndex: 'auth',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '菜单ICON',
                       dataIndex: 'icon',
                       valueType:'digit',
                       formItemProps: {
                           rules: [],
                       },
                       renderFormItem:(schema,config,form)=>{
                           return (
                               <IconPicker
                                   value={config.value}
                                   onChange={(key)=>{
                                       form.setFieldValue("icon",key)
                                   }}
                               ></IconPicker>
                           )
                       }
                   },
                   {
                       title: '菜单URL',
                       dataIndex: 'url',
                       colProps: {
                           span: 24
                       },
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '状态',
                       dataIndex: 'status',
                       colProps: {
                           offset:22,
                           span: 2
                       },
                       valueType:'switch'
                   },
               ]
           }else if(type==='MENU'){
               return [
                   {
                       title: '菜单名称',
                       dataIndex: 'name',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '菜单排序',
                       dataIndex: 'sort',
                       valueType:'digit',
                       colProps: {
                           span: 12
                       },
                       fieldProps:{
                           style:{
                               width:"100%"
                           }
                       },
                       colSize:12,
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '权限标识',
                       dataIndex: 'auth',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '菜单ICON',
                       dataIndex: 'icon',
                       valueType:'digit',
                       formItemProps: {
                           rules: [],
                       },
                       renderFormItem:(schema,config,form)=>{
                          return (
                              <IconPicker
                                  value={config.value}
                                  onChange={(key)=>{
                                      form.setFieldValue("icon",key)
                                  }}
                              ></IconPicker>
                          )
                       }
                   },
                   {
                       title: '菜单URL',
                       dataIndex: 'url',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '菜单组件路径',
                       dataIndex: 'component',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '上级菜单',
                       dataIndex: 'parentId',
                       valueType: 'treeSelect',
                       colProps: {
                           span: 24
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请设置上级菜单',
                               },
                           ],
                       },
                       request:async (params,props) => {
                           const result = await getMenuList({})
                           return buildDeptSelect(result.data||[])
                       }
                   },
                   {
                       title: '状态',
                       dataIndex: 'status',
                       colProps: {
                           offset: 22,
                           span: 2
                       },
                       valueType:'switch'
                   },
               ]
           }else{
               return [
                   {
                       title: '按钮名称',
                       dataIndex: 'name',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '权限标识',
                       dataIndex: 'auth',
                       colProps: {
                           span: 12
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请输入菜单名',
                               },
                           ],
                       }
                   },
                   {
                       title: '按钮排序',
                       dataIndex: 'sort',
                       valueType:'digit',
                       colProps: {
                           span: 12
                       },
                       fieldProps:{
                           style:{
                               width:"100%"
                           }
                       },
                       colSize:12,
                       formItemProps: {
                           rules: [],
                       }
                   },
                   {
                       title: '上级菜单',
                       dataIndex: 'parentId',
                       valueType: 'treeSelect',
                       colProps: {
                           span: 24
                       },
                       formItemProps: {
                           rules: [
                               {
                                   required: true,
                                   message: '请设置上级菜单',
                               },
                           ],
                       },
                       request:async (params,props) => {
                           const result = await getMenuList({})
                           return buildDeptSelect(result.data||[])
                       }
                   },
                   {
                       title: '状态',
                       dataIndex: 'status',
                       colProps: {
                           offset: 22,
                           span: 2
                       },
                       valueType:'switch'
                   },

               ]
           }
        }
    }
]


export const startData = {
    status:true,
    type:"DIRECTORY"
}