import type {MenuProps} from 'antd';
import React from "react";
import * as icons from '@ant-design/icons';
import {LoginUser} from "../entity/System/User";
import {MenuVo} from "../entity/System/Menu";
const Icon = icons.default

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
    label: React.ReactNode,
    key: string,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

// const items: MenuItem[] = [
//     getItem('DashBoard', '/system/dashboard',<BarChartOutlined />),
//     getItem('系统管理', '/system', <BranchesOutlined />,[
//         getItem('用户管理', '/system/user'),
//         getItem('角色管理', '/system/role'),
//         getItem('菜单管理', '/system/menu'),
//         getItem('部门管理', '/system/dept'),
//     ]),
//     getItem('iLearn管理', '/ilearn', <BookOutlined />,[
//         getItem('用户管理', '/ilearn/user'),
//         getItem('角色管理', '/ilearn/role'),
//         getItem('菜单管理', '/ilearn/menu'),
//         getItem('部门管理', '/ilearn/dept'),
//     ]),
// ];

function  getItems(menu:MenuVo){
    if(menu.type==="BUTTON"){
        return null
    }
    let children:MenuItem[] = []
    if(menu.children && menu.children?.length!==0){
         children = menu.children.filter(menu=>menu.type!=='BUTTON').map(menu=>{
           return getItems(menu)
        })
    }
    if(children.length>0){
        return getItem(menu.name,menu?.url||"",menu.icon ? <Icon component={icons[menu.icon] as React.ForwardRefExoticComponent<any>} /> : null , children)
    }else{
        return getItem(menu.name,menu?.url||"",menu.icon ? <Icon component={icons[menu.icon] as React.ForwardRefExoticComponent<any>} /> : null )
    }

}

export function useMenuItems(user:LoginUser){
    let newItems = user.menus?.filter(menu=>menu.type!=='BUTTON').map(menu=>{
        return getItems(menu)
    })
    return newItems
}