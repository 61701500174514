// ProtectedRoute.js
import {useEffect} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';
import {useAppSelector} from "../../hook/storeHook";

const AuthRoute = () => {
    const navigate = useNavigate()
    const hasLogin = useAppSelector((state)=>state.user.hasLogin)
    useEffect(() => {
        if (!hasLogin) {
            // 重定向到登录页面
            navigate("/login")
        }
    }, [hasLogin,navigate]);
    return (
        <Outlet></Outlet>
    )
}

export default AuthRoute;