import {Page, Result} from "../../entity";
import {api} from "../Request";
import {requestTest} from "../../utils";
import {GodboxKeywordQuery, GodboxKeywordVo} from "../../entity/Godbox/Keyword";

async function getKeywordPage(menuDto: GodboxKeywordQuery):Promise<Result<Page<GodboxKeywordVo>>> {
    let res = await api.post<Result<Page<GodboxKeywordVo>>>('/godbox/keyword/page',{
        data:menuDto
    });
    requestTest(res)
    return res
}
async function saveUpdateKeyword(menuDto: GodboxKeywordVo):Promise<Boolean|undefined>{
    let res = await api.post<Result<Boolean>>('/godbox/keyword/saveUpdate',{
        data:menuDto
    });
    return requestTest(res)
}

async function delKeyword(id: Number):Promise<Boolean|undefined>{
    let res = await api.get<Result<Boolean>>('/godbox/keyword/delete',{
        params:{
            id
        }
    });
    return requestTest(res)
}

export {
    getKeywordPage,
    saveUpdateKeyword,
    delKeyword
}