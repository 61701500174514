import {Status, tableConfig} from "../../../entity";
import React, {useState} from "react";
import {ProColumns, ProFormColumnsType} from "@ant-design/pro-components";
import {PlusOutlined} from '@ant-design/icons';
import {Image, Tag, Upload, UploadFile, UploadProps} from "antd";

import {getBaseTableColumns} from "../../../utils";
import CosUtil from "../../../utils/cos";
import type {FormInstance} from "antd/lib/form";
import {GetProp} from "../../../entity/types";


export function getTableColumns(fns: tableConfig, reactElementFn: (record: any) => React.ReactElement[] = (record) => []): ProColumns[] {
    return [
        {
            dataIndex: 'index',
            valueType: 'indexBorder',
            search: false,
            width: 48,
        },
        {
            title: '图标',
            dataIndex: 'iconImage',
            search: false,
            render: (_, record: any) => {
                let image = record['iconImage']
                return <Image style={{width:"50px",height:"50px"}} src={image}></Image>
            }
        },
        {
            title: '名字',
            dataIndex: 'name',
        },
        {
            title: '域名',
            dataIndex: 'baseUrl',
            search: false,
        },
        {
            title: 'webview渲染',
            dataIndex: 'useWebviewVideo',
            search: false,
            render: (_, record: any) => {
                switch (record.useWebviewVideo as boolean) {
                    case true:
                        return <Tag color="geekblue">是</Tag>
                    case false:
                        return  <Tag color="geekblue">否</Tag>
                }
            }
        },
        {
            title: '是否可用',
            dataIndex: 'status',
            search: false,
            render: (_, record: any) => {
                switch (record.status as Status) {
                    case "SHOW":
                        return <Tag color="geekblue">是</Tag>
                    case "HIDDEN":
                        return  <Tag color="geekblue">否</Tag>
                }
            }
        },
        {
            title: '爬虫',
            dataIndex: 'crawler',
            search: false,
        },
        {
            title: '发布页面',
            dataIndex: 'releasePageUrl',
            search: false,
            hideInTable:true
        },
        ...getBaseTableColumns(fns, reactElementFn, false, true, false)
    ];
}

// const StatusEnum = {
//     SINGLE: {
//         text: '单品',
//         status: 'SINGLE',
//     },
//     BANNER: {
//         text: '活动',
//         status: 'BANNER',
//     },
// };
export const formColumns: ProFormColumnsType[] = [
    {
        dataIndex: "id",
        colProps: {
            span: 0
        },
        renderFormItem: () => {
            return <div style={{display: "none"}}></div>
        }
    },
    {
        valueType: "divider"
    },
    {
        title: "基础信息",
        valueType: "group",
        columns: [
            {
                title: '数据源名字',
                dataIndex: 'name',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '数据源名字',
                        },
                    ],
                }
            },
            {
                title: '是否可用',
                dataIndex: 'status',
                colProps: {
                    span: 12,
                    onChange:(...rest)=>{
                        console.log(rest)
                    }
                },

                valueType:"switch",
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: 'webview渲染',
                dataIndex: 'useWebviewVideo',
                colProps: {
                    span: 12
                },
                valueType:"switch"
            },
            {
                title: '数据源图标',
                dataIndex: 'iconImage',
                colProps: {
                    span: 24
                },
                renderFormItem:(schema, config, form)=>{
                    return <UploadImage form={form}></UploadImage>
                },

                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入图片链接',
                        },
                    ],
                }
            },
            {
                title: '爬虫',
                dataIndex: 'crawler',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入普通长链',
                        },
                    ],
                }
            },
            {
                title: '域名',
                dataIndex: 'baseUrl',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
            {
                title: '发布页',
                dataIndex: 'releasePageUrl',
                colProps: {
                    span: 24
                },
                formItemProps: {
                    rules: [
                        {
                            required: true,
                            message: '请输入昵称',
                        },
                    ],
                }
            },
        ]
    },
]
export const startData = {
    status: true,
    image:""
}


type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];

const getBase64 = (file: FileType): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
    });

const UploadImage: React.FC<{ form: FormInstance }> = (props) => {
    const {form} = props
    const iconImage = form.getFieldValue("iconImage") as string
    let  images:UploadFile[] = []
    if(iconImage){
        images.push({
            uid: "-1",
            name: 'image.png',
            status: 'done',
            url: iconImage,
        })
    }

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState<UploadFile[]>(images);
    const handlePreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj as FileType);
        }
        setPreviewImage(file.url || (file.preview as string));
        setPreviewOpen(true);
    };

    const handleChange: UploadProps['onChange'] = (data)=>{
        console.log(data)

        setFileList(data.fileList);
    }


    const uploadButton = (
        <button style={{ border: 0, background: 'none' }} type="button">
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Upload</div>
        </button>
    );
    const customRequest:UploadProps['customRequest'] = (options)=> {
        console.log(options)

        CosUtil.uploadFile({
            Body: options.file,
            Headers: undefined,
            // @ts-ignore
            Key: `image/${options.file.name}`,
            onProgress(data) {
                options!.onProgress!({percent:data.percent})
            },
            onTaskReady: undefined
        }).then((data) => {
            //`https://${data.Location}`
            fileList.splice(0,fileList.length)
            fileList.push({
                uid: `-${images.length}`,
                name: 'image.png',
                status: 'done',
                url:`https://${data.Location}`,
            });

            form.setFieldValue("iconImage",`https://${data.Location}`)
            setFileList(fileList)
        }).catch(options.onError)
    }
    return (
        <>
            <Upload
                multiple={false}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                customRequest={customRequest}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
            {previewImage && (
                <Image
                    wrapperStyle={{ display: 'none' }}
                    preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) => !visible && setPreviewImage(''),
                    }}
                    src={previewImage}
                />
            )}
        </>
    );
};
