import React, {useRef, useState} from "react";
import "./Menu.scss"
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {MenuVo} from "../../../entity/System/Menu";
import {formColumns, getTableColumns, startData} from "../Menu/MenuConfig";
import {addMenu, delMenu, getMenuList} from "../../../apis/System/MenuApi";
import {PlusOutlined} from "@ant-design/icons";

export default function Menu (){
     const [modelShow, setModelShow] = useState(false)
     const actionRef = useRef<ActionType>();
     const [form] = Form.useForm();
     return (
         <div>
              <BaseTable<MenuVo>
                  tableConfig = {{
                       columns:getTableColumns({
                            editFn:(record)=>{
                                 form.setFieldsValue(record)

                                 if(record.status==="SHOW"){
                                      form.setFieldValue("status",true)
                                 }else{
                                      form.setFieldValue("status",false)
                                 }
                                 setModelShow(true)
                            },
                            deleteFn:async (record)=>{
                                 await delMenu(record.id)
                                 actionRef.current?.reload()
                                 notification.success({
                                      message:"删除成功"
                                 })
                            },
                       }),
                       actionRef:actionRef,
                       cardBordered:true,
                       rowKey:"id",
                       toolBarRender:()=> [
                            <Button
                                key="button"
                                icon={<PlusOutlined />}
                                onClick={() => {
                                     setModelShow(true)
                                }}
                                type="primary"
                            >
                                 新建
                            </Button>
                       ],
                       request:async (params, sort, filter) => {
                            console.log(sort, filter,params)
                            const result =  await getMenuList({
                                 name:params.name
                            });
                            return {
                                 data: result.data ,
                                 // success 请返回 true，
                                 // 不然 table 会停止解析数据，即使有数据
                                 success: result.success,
                                 // 不传会使用 data 的长度，如果是分页一定要传
                                 total: result.data?.length,
                            }
                       },
                       pagination:{
                            pageSize: 10,
                       },
                       headerTitle:"菜单数据"
                  }}
                  formConfig={{
                       form:form,
                       initialValues:startData,
                       shouldUpdate:true,
                       title:"新建部门",
                       grid:true,
                       layoutType:"ModalForm",
                       open:modelShow,
                       onOpenChange:setModelShow,
                       columns:formColumns,
                       onFinish: async (formData)=>{

                            formData.status = formData.status?"SHOW":"HIDDEN"
                            formData.sort = formData.sort??1
                            console.log(formData)
                            await addMenu(formData)
                            actionRef.current?.reload()
                            setModelShow(false)
                            return true
                       },
                       modalProps:{
                            destroyOnClose:true,
                            mask:true
                       }
                  }}
              ></BaseTable>
         </div>
     )
}