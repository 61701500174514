import React, {useState} from 'react';
import {HomeOutlined, MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined,} from '@ant-design/icons';
import "./App.css"
import {Breadcrumb, Button, Flex, Layout, Menu, theme} from 'antd';
import {Outlet, useNavigate} from "react-router-dom";
import {useMenuItems} from "./controller/HomeController";
import {MenuItemType} from "antd/es/menu/hooks/useItems";
import {useAppSelector} from "./hook/storeHook";

const {Header, Sider, Content} = Layout;

const App: React.FC = () => {
    const [collapsed, setCollapsed] = useState(false);
    const {
        token: {colorBgContainer, borderRadiusLG},
    } = theme.useToken();
    const navigate = useNavigate()
    const user = useAppSelector((state) => state.user)
    const items = useMenuItems(user)

    function select(item:MenuItemType){
        navigate(item.key as string)
    }

    return (
        <Layout style={{height: "100vh"}}>
            <Sider width={"256px"} breakpoint="sm" trigger={null} collapsible collapsed={collapsed}>
                <div className="demo-logo-vertical"/>
                <Menu
                    theme="dark"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={items}
                    onSelect={select}
                />
            </Sider>
            <Layout>
                <Header style={{padding: 0, background: colorBgContainer}}>
                    <Flex align={"center"}>
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <Breadcrumb
                            items={[
                                {
                                    href: '',
                                    title: <HomeOutlined/>,
                                },
                                {
                                    href: '',
                                    title: (
                                        <>
                                            <UserOutlined/>
                                            <span>Application List</span>
                                        </>
                                    ),
                                },
                                {
                                    title: 'Application',
                                },
                            ]}
                        />
                    </Flex>
                </Header>
                <Content
                    style={{
                        margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                        background: colorBgContainer,
                        borderRadius: borderRadiusLG,
                        maxHeight:800,
                        overflowX:"scroll"
                    }}
                >
                    <Outlet></Outlet>
                </Content>
            </Layout>
        </Layout>
    );
};

export default App;