import React, {useRef, useState} from "react";
import {ActionType} from "@ant-design/pro-components";
import {Button, Form, notification} from "antd";
import {BaseTable} from "../../../components/BaseTable/BaseTable";
import {UserDto, UserVo} from "../../../entity/System/User";
import {formColumns, getTableColumns, startData} from "./KeywordConfig";
import {delKeyword, getKeywordPage, saveUpdateKeyword} from "../../../apis/Godbox/KeywordApi";
import {PlusOutlined} from "@ant-design/icons";


export default function Keyword() {

    const actionRef = useRef<ActionType>();
    const [form] = Form.useForm();
    const [modelShow, setModelShow] = useState(false)
    return (
        <div>
            <BaseTable<UserVo, UserDto>
                tableConfig={{
                    columns: getTableColumns({
                        hideTimeSearch: {
                            hideCreateTime: true,
                            hideUpdateTime: true,
                        },
                        editFn: (record) => {
                            form.setFieldsValue(record)
                            setModelShow(true)
                        },
                        deleteFn: async (record) => {
                            console.log(record)
                            await delKeyword(record.id)
                            actionRef.current?.reload()
                            notification.success({
                                message: "删除成功"
                            })
                        },
                    }, (record) => {
                        return []
                    }),
                    cardBordered: true,
                    rowKey: "id",
                    actionRef: actionRef,
                    toolBarRender: () => [
                        <Button
                            key="button"
                            icon={<PlusOutlined />}
                            onClick={() => {
                                setModelShow(true)
                            }}
                            type="primary"
                        >
                            新建
                        </Button>
                    ],
                    request: async (params, sort, filter) => {
                        console.log(sort, filter, params)
                        const result = await getKeywordPage({
                            page: params.current,
                            size: params.pageSize,
                            keyword: params.keyword
                        })
                        return {
                            data: result.data?.list,
                            // success 请返回 true，
                            // 不然 table 会停止解析数据，即使有数据
                            success: result.success,
                            // 不传会使用 data 的长度，如果是分页一定要传
                            total: result.data?.total,
                        }
                    },
                    pagination: {
                        pageSize: 5,
                    },
                    headerTitle: "用户数据"
                }}
                formConfig={{
                    form: form,
                    width: 500,
                    initialValues: startData,
                    title: "新建用户",
                    grid: true,
                    layoutType: "ModalForm",
                    open: modelShow,
                    onOpenChange: setModelShow,
                    columns: formColumns,
                    onFinish: async (formData) => {

                        await saveUpdateKeyword(formData)
                        actionRef.current?.reload()
                        setModelShow(false)
                        return true
                    },
                    modalProps: {
                        destroyOnClose: true,
                        mask: true
                    }
                }}
            ></BaseTable>
        </div>
    )
}